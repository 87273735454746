:root {
  color-scheme: dark
}

.App {
  --primary-dark: #212529;
  --text-light: rgb(250, 250, 250);
  --background-light: #f0f0f0;
  --background-dark-primary: rgba(33, 37, 43, 0.25);
  --background-dark-accent: rgba(33, 37, 43, 0.75);
  --background-dark-secondary: #131518;

  background-color: var(--background-light);
}

.hero {
  min-height: 100vh;
  min-height: 100svh;
  padding-bottom: 10rem !important;
}

.rounded {
  border-radius: 1rem !important;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: brightness(0.4);
}

.hero-img-container {
  height: 12rem;
}

.hero-img {
  border: solid 0.1rem var(--text-light);
  background-color: rgba(255, 255, 255, 0.4);
  transition: all .3s ease-in-out;
}

.hero-heading-primary {
  font-size: 2.75rem;
  font-weight: 600;
  color: var(--text-light);
}

.hero-heading-secondary {
  font-size: 1.4rem;
  font-weight: 400;
  color: rgb(210, 210, 210);
  word-wrap: balance !important;
}

.hero-button {
  padding: 0;
  height: 3rem;
  display: flex;
  justify-content: space-around;
  background-color: transparent;
  width: 9rem;
  font-size: 1.3rem;
  font-weight: 400;
  border: solid 0.075rem var(--text-light);
}

.hero-button>* {
  color: var(--text-light);
}

.icon {
  font-size: 1.6rem;
}

.hero-button:hover {
  border: solid 0.075rem var(--text-light);
  background-color: rgba(255, 255, 255, 0.3) !important;
  transition: all .1s ease-in-out;
}

.projects,
.skills,
.aboutme {
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0;
}

.projectcard {
  outline: none;
  border: none;
  transition: all .1s ease-in-out;
  color: var(--text-light);
}

.myheader {
  background-color: transparent;
  height: 5rem;
  font-weight: 500 !important;
  color: var(--text-light);
}

.card-header {
  font-size: 1.6rem !important;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  font-weight: 500;
  word-wrap: balance;
}

.card-body {
  padding: 1rem;
  flex-direction: column;
}

.icon {
  text-align: center;
  padding: 0;
}

.github-button {
  background-color: var(--background-light);
  color: var(--primary-dark);
  font-size: 1rem;
  height: 2.25rem;
  opacity: 0 !important;
  transition: all .1s ease-in-out;
}

.projectcard:hover .github-button {
  opacity: 1 !important;
  transition: all .1s ease-in-out;
}

.github-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.project-bottom {
  min-height: 2.75rem;
}

.project-info {
  font-size: 1.1rem;
  font-weight: 400;
  flex-grow: 1;
  box-sizing: border-box;
}

.badges {
  flex-wrap: wrap !important;
}

.badge {
  font-size: 0.95rem;
  font-weight: 700;
  background: transparent;
}

.myfooter {
  background: transparent;
}

.myfooter>*,
.footer-text>* {
  color: var(--text-light);
}

.header-img {
  filter: invert(1);
  height: 3.2rem !important;
  margin-left: 0.5rem !important;
}

.footer-text {
  user-select: none;
  width: 50%;
}

.scroll-button {
  padding: 0;
  color: var(--text-light);
  border-radius: 2rem;
  z-index: 10 !important;
  cursor: pointer;
}

.projects-row {
  justify-content: center;
  width: 100%;
  max-width: 100rem !important;
}

.hero-container {
  width: 35rem;
  background: none;
  animation: parallaxanim linear;
  animation-timeline: scroll() !important;
  transform: translateY(0rem);
}

.skill {
  height: 4rem;
  font-weight: 500;
  background-color: var(--background-dark-accent);
  color: var(--text-light);
}

.skill-title {
  font-size: 1.2rem;
}

.skill-img {
  filter: invert(1);
}

.skills-row {
  width: 70rem !important;
}

.content {
  background-color: var(--background-dark-primary);
  backdrop-filter: blur(3rem);
  position: relative;
}

.about-me-row {
  width: 100%;
  max-width: 85rem !important;
}

.aboutme-section {
  min-height: 23rem;
  height: auto !important;
  background-color: var(--background-dark-accent);
}

.aboutme-img {
  object-fit: cover;
}

.aboutme-header {
  height: 5rem;
  color: var(--text-light);
  font-weight: 400;
  font-size: 2rem;
}

.aboutme-content {
  color: var(--text-light);
  font-weight: 300;
  font-size: 1.1rem;
}

@keyframes parallaxanim {
  0% {
    transform: translateY(0rem);
  }

  100% {
    transform: translateY(18rem);
  }
}

@media screen and (min-width: 1020px) {
  /* larger breakpoint */

  .myheader {
    font-size: 3rem;
  }

  .project {
    width: 29rem !important;
    min-height: 15.5rem;
  }

  .scroll-button {
    bottom: 5rem;
    font-size: 3.5rem;
  }

  .hero-container {
    padding: 1.5rem !important;
  }
}

@media screen and (max-width: 1019px) {
  /* smaller breakpoint */

  .projectcard {
    margin-left: 0;
    margin-right: 0;
  }

  .myheader {
    font-size: 2.5rem;
  }

  .projects-row {
    width: 100% !important;
  }

  .project {
    width: 45rem !important;
    min-height: 14rem;
  }

  .scroll-button {
    bottom: 5rem;
    font-size: 3.5rem;
  }

  .skills-row {
    justify-content: center !important;
  }

  .hero-container {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
  }

  .skill {
    width: 30% !important;
  }
}

@media screen and (max-width: 720px) {
  /* special breakpoint */

  .skill {
    width: 100% !important;
  }

  .skill-content,
  .skill-title {
    text-align: center !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 456px) {
  /* smallest breakpoint */

  .github-button .visit-text {
    display: none !important;
  }

  .github-button {
    width: 2.1rem !important;
  }

  .footer-text {
    font-size: 0.9rem !important;
  }
}